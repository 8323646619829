* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.d-none {
    display: none !important;
}
